import React from 'react'
import Dashborad from './Dashborad';
import Header from '../headerfooterpre/header';

export default function Index() {
  return (
    <>
    <Header/>
    <Dashborad/>
    </>
    
  )
}
