import React from 'react'
import Signup from './signup';
import Header from './header'
import Footer from '../headerfooterpre/Footer';

export default function Index() {
  return (
    <>
    <Header/>
    <Signup/>
    <Footer/>
    </>
  )
}
